import { useNavigate } from 'react-router-dom';
import './SignUp.css';
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import logo from './logo.svg';

const SignUp = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [organization, setOrganization] = useState(''); // Added organization state

    const organizations = [
        'Lahey: (Burlington, MA)',
        'RIMI: (Providence, RI)',
        'Nashua/SJ: (Nashua, NH)'
    ];

    const [spinSpeed, setSpinSpeed] = useState(1);

    const cardElementOptions = {
        style: {
            base: {
                fontFamily: 'Arial, sans-serif',
                fontSize: '16px',
                color: '#44e293', // Light blue color
            },
        },
        options: {
            classes: {
                base: 'custom-base-class',
                complete: 'custom-complete-class',
                empty: 'custom-empty-class',
                focus: 'custom-focus-class',
                invalid: 'custom-invalid-class',
            },
            placeholder: 'Card Number',
            iconStyle: 'solid',
        },
    };

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSpinSpeed(1); // Reset spin speed to normal initially

        // Start accelerated spinning after a short delay
        setTimeout(() => {
            setSpinSpeed(2);
        }, 10);

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);

            navigate('/error', { state: { message: 'Payment method creation failed. Please contact support@radscriber.com' }});
        } else {
            console.log('[PaymentMethod]', paymentMethod);
            try {
                const response = await fetch('https://us-central1-radscriber.cloudfunctions.net/signUp/signup', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        password: password,
                        organization: organization, // Include organization in the request body
                        paymentMethodId: paymentMethod.id,
                    }),
                });

                if (!response.ok) {
                    console.error('Signup request failed');

                    navigate('/error', { state: { message: 'Signup failed. Please contact support@radscriber.com' }});
                    return;
                }

                const data = await response.json();
                console.log(data);
                // After successful signup and payment
                navigate('/success', { state: { name: firstName, email: email } });
            } catch (error) {
                console.log(error);

                navigate('/error', { state: { message: 'Error occurred during signup. Please contact support@radscriber.com' }});
            }
        }
    };

    return (
        <div className="signup">
             <img src={logo} alt="Logo" className="logo" style={{ animationDuration: `${2 / spinSpeed}s` }} />
            <h1>RadScriber</h1>
            <p>New Customer Registration</p>
           
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                <select value={organization} onChange={(e) => setOrganization(e.target.value)} required>
                    <option value="">Select Organization</option>
                    {organizations.map((org, index) => (
                        <option key={index} value={org}>{org}</option>
                    ))}
                </select>
                <CardElement options={cardElementOptions} />
                <button type="submit" disabled={!stripe}>Sign Up</button>
            </form>
        </div>
    );
};

export default SignUp;
