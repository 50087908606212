import { useLocation } from 'react-router-dom';
import './SuccessPage.css';

const SuccessPage = () => {
    const location = useLocation();
    const name = location.state ? location.state.name : '';
    const email = location.state ? location.state.email : '';

    return (
        <div className="success-page">
            <img src="https://png.pngtree.com/png-clipart/20230507/ourmid/pngtree-funny-cat-sunglasses-on-isolated-background-png-image_7088118.png" alt="Successful cat" className="success-image" />
            <h1>Way to go!</h1>
            {name && <div> <h1> {name}! </h1> <p>You've successfully signed up for RadScriber! You can now log-in to the RadScriber desktop application using your <b>{email} </b>account and password.</p></ div> }

        </div>
    );
};

export default SuccessPage;
