import { useLocation } from 'react-router-dom';
import './ErrorPage.css'; // you can create a separate CSS file for Error page styling

const ErrorPage = () => {
    const location = useLocation();
    const { message } = location.state || { message: "An error occurred. Please contact support@radscriber.com. We'll be sure to help you out!" };

    return (
        <div className="error-page">
            <img src="https://www.womansworld.com/wp-content/uploads/2018/05/sad-cat-luhu.jpg?w=215" alt="Successful cat" className="success-image" />
            <h1>Whoops, something went wrong..</h1>
            <p>{message}</p>
        </div>
    );
};

export default ErrorPage;
