import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


import HomePage from './HomePage';  // Import your existing HomePage component
import SignUp from './SignUp'; // Import the SignUp component
import SuccessPage from './SuccessPage';
import ErrorPage from './ErrorPage';

const stripePromise = loadStripe('pk_test_51NExOFKSGyc3UIh8c0y9Vw5nI9dS17eLPCdkCLf21jPcABVB4P2f0PDPSVfYwUlJnwYQ9GNyYvuj8NWsTdZVH9VS00jVkapLSv');

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/newcustomer" element={<SignUp />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/error" element={<ErrorPage />} />
        </Routes>
      </Router>
    </Elements>
  );
}

export default App;
