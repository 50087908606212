import logo from './logo.svg';
import './HomePage.css';
import { Component } from 'react';
import { useState } from "react";
import Select from "react-select";




class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      name: { firstName: "RadScriber v2.0", lastName: "" },
      saying: "",
      template: "",
      text: 'No pleural effusion. No adenopathy. Status post cholecystectomy. Lesion in segment 7 with peripheral nodular discontinous enhancement. Multiple bilateral renal cysts. There are no focal lesions within the adrenal glands. Severe calcific atherosclerosis without aortic aneurysm. Mild gas and stool distension of the colon. Moderate thoracic spondylosis. L5 compression fracture. Mild body wall edema. Calcified uterine fibroids. Fat containing left inguinal hernia. Small hiatal hernia. No biliary duct dilation.',
      response: null, // Change initial state to null
      postRequest: "",
      loading: false, // Add new state property
      template: "",
      response: null,
      options: []
    };
    this.fetchData();
    this.handleChange = this.handleChange.bind(this);
  }

  fetchData = async () => {
    const response = await fetch('https://radscriber.com/menu_items.json');
    const data_menu_items = await response.json();
    console.log(data_menu_items)
    const options = data_menu_items.map(item => ({
      value: item, 
      label: item
    }));

    this.setState({ options });
    console.log(options)
  }



  handleChange = (event) => {
    this.setState({text: event.target.value});

  }

  formatText = (inputText) => {
    // Remove line breaks and new paragraph formatting
    const removedLineBreaks = inputText.replace(/(\r\n|\n|\r)/gm, ' ');

    // Convert to sentence case
    const sentences = removedLineBreaks.split('. ');
    const sentenceCaseText = sentences.map((sentence) => {
      const firstChar = sentence.charAt(0).toUpperCase();
      const restOfSentence = sentence.slice(1);
      return firstChar + restOfSentence;
    }).join('. ');

    return sentenceCaseText;
  }

  

  handleAutoPasteClick = () => {
    if (navigator.clipboard && navigator.clipboard.readText) {
      navigator.clipboard.readText().then((pastedText) => {
        this.setState({ text: pastedText }, () => {
          this.handleSubmit(new Event("submit"));
        });
      });
    } else {
      // Clipboard API not supported
      console.error("Clipboard API not supported in this browser.");
      // Display an error message or provide an alternative approach for auto-pasting
    }
  };
  

  handleCopyClick = () => {
    console.log('LOOK BELOW!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    console.log(this.state.template)
    
    const { response } = this.state;
    var formattedResponse = response
      .replace("LYMPH_NODES:", "LYMPH NODES:")
      .replace(
        /(LUNGS\/PLEURA:|MEDIASTINUM\/VASCULAR:|LYMPH NODES:|BONES\/SOFT TISSUES:|UPPER ABDOMEN:|LOWER THORAX:|LIVER:|GALLBLADDER\/BILIARY:|PANCREAS:|SPLEEN:|ADRENAL GLANDS:|KIDNEYS\/URETERS:|GI TRACT\/PERITONEUM:|PELVIC ORGANS:|BONES\/SOFT TISSUES:|LYMPH_NODES:|VASCULAR:|Lungs:|Cardiomediastinum:|Osseous:|Lines\/Tubes\/Devices:|Other:)/g,
        (match) => `\r\n\r\n${match}`
      )
      .replace(/\r\n\s*\r\n/g, "\r\n");

  
      if (this.state.template === "RIMI: Abdomen/Pelvis") {
        var formattedResponse = formattedResponse
                          .replace('Pelvic organs:', '\r\nPelvic organs:')
                          .replace('Musculoskeletal:','\r\nMusculoskeletal:')
                          .replace('Lower chest: Normal.', 'Lower chest: Normal.')
                          .replace('Liver: ', 'Liver: No suspicious liver lesions.')
                          .replace('Biliary: Normal.', 'Biliary: No biliary dilatation.')
                          .replace('Gallbladder: Normal.', 'Gallbladder: Normal.')
                          .replace('Pancreas: Normal.', 'Pancreas: Normal.')
                          .replace('Spleen: Normal.', 'Spleen: Normal.')
                          .replace('Adrenals: Normal.', 'Adrenals: Normal.')
                          .replace('Kidneys/Ureters: Normal.', 'Kidneys/Ureters: No hydronephrosis or suspicious mass.')
                          .replace('Gastrointestinal tract: Normal.', 'Gastrointestinal tract: Normal.')
                          .replace('Peritoneal cavity: Normal.', 'Peritoneal cavity: No ascites. No free air.')
                          .replace('Pelvic organs: Normal.', 'Pelvic organs: No pelvic masses or inflammatory process is identified.')
                          .replace('Lymph nodes: Normal.', 'Lymph nodes: No pathologic lymphadenopathy.')
                          .replace('Abdominal wall: Normal.', 'Abdominal wall: Normal.')
                          .replace('Vascular structures: Normal.', 'Vascular structures: Patent.')
                          .replace('Musculoskeletal: Normal.', 'Musculoskeletal: No acute abnormality identified.');
      }

    
    



  
    const tempElement = document.createElement("textarea");
    tempElement.value = formattedResponse;
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand("copy");
    document.body.removeChild(tempElement);
  };
  
  
  

  handleSubmit(event) {
    event.preventDefault();
    const { template, text } = this.state;
    var data = { template, text };

    const substringsToDelete = [
      "appears normal",
      "are unremarkable",
      "appear unremarkable"
    ];

    // Split the text into sentences using period as the delimiter
  const sentences = text.split(". ");

  // Filter out the sentences that contain any of the substrings to be deleted
  const filteredSentences = sentences.filter((sentence) => {
    const sentenceLower = sentence.toLowerCase();
    return !substringsToDelete.some((substring) =>
      sentenceLower.includes(substring)
    );
  });

  // Join the filtered sentences back together to form the updated text
  const updatedText = filteredSentences.join(". ");

  var data = { template, text: updatedText, user_id: "radscriber.com" };

  
    this.setState({ postRequest: JSON.stringify(data),  loading: true  });


    // Add fade-out class to "Processing..." message after 1 second
  setTimeout(() => {
    this.setState({ fadeOut: true });
  }, 1000);




    fetch("https://compute.radscriber.com/predict?=", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("API response:", result);
          this.setState({ response: result.completion, loading: false  });
  
          // Copy to clipboard
          const finalResponse = result.completion
          .replace("LYMPH_NODES:", "LYMPH NODES:")
          .replace(
            /(LUNGS\/PLEURA:|MEDIASTINUM\/VASCULAR:|LYMPH NODES:|BONES\/SOFT TISSUES:|UPPER ABDOMEN:|LOWER THORAX:|LIVER:|GALLBLADDER\/BILIARY:|PANCREAS:|SPLEEN:|ADRENAL GLANDS:|KIDNEYS\/URETERS:|GI TRACT\/PERITONEUM:|PELVIC ORGANS:|BONES\/SOFT TISSUES:|LYMPH_NODES:|VASCULAR:|Lungs:|Cardiomediastinum:|Osseous:|Lines\/Tubes\/Devices:|Other:)/g,
            (match) => `\r\n\r\n${match}`
          )
          .replace(/\r\n\s*\r\n/g, "\r\n");
          
          
      if (this.state.template === "RIMI: Abdomen/Pelvis") {
        const finalResponse = finalResponse
                          .replace('Pelvic organs:', '\r\nPelvic organs:')
                          .replace('Musculoskeletal:','\r\nMusculoskeletal:')
                          .replace('Lower chest: Normal.', 'Lower chest: Normal.')
                          .replace('Liver: Normal.', 'Liver: No suspicious liver lesions.')
                          .replace('Biliary: Normal.', 'Biliary: No biliary dilatation.')
                          .replace('Gallbladder: Normal.', 'Gallbladder: Normal.')
                          .replace('Pancreas: Normal.', 'Pancreas: Normal.')
                          .replace('Spleen: Normal.', 'Spleen: Normal.')
                          .replace('Adrenals: Normal.', 'Adrenals: Normal.')
                          .replace('Kidneys/Ureters: Normal.', 'Kidneys/Ureters: No hydronephrosis or suspicious mass.')
                          .replace('Gastrointestinal tract: Normal.', 'Gastrointestinal tract: Normal.')
                          .replace('Peritoneal cavity: Normal.', 'Peritoneal cavity: No ascites. No free air.')
                          .replace('Pelvic organs: Normal.', 'Pelvic organs: No pelvic masses or inflammatory process is identified.')
                          .replace('Lymph nodes: Normal.', 'Lymph nodes: No pathologic lymphadenopathy.')
                          .replace('Abdominal wall: Normal.', 'Abdominal wall: Normal.')
                          .replace('Vascular structures: Normal.', 'Vascular structures: Patent.')
                          .replace('Musculoskeletal: Normal.', 'Musculoskeletal: No acute abnormality identified.');
      }

    
    




          navigator.clipboard.writeText(finalResponse);
  
          // Show "Report Copied to Clipboard" message
          this.setState({ showClipboardMessage: true });
          setTimeout(() => {
            this.setState({ showClipboardMessage: false });
          }, 5000);
        },
        (error) => {
          console.log("API error:", error);
          this.setState({ response: "Error: " + error.message });
        }
      );
  }




  render() {
    document.title = 'Radscriber Web App'
    const { response, loading } = this.state;
    const showExampleTemplate = !response; // Show example template if no response yet
    return (
      
      <div className="App">
        <div className="left_screen">
          <header className="App-header">

          <div className="left-container-div">
          {loading && (<p className="loading-text">Processing...</p>)}
          <img src={logo} className={`App-logo ${loading ? "App-logo-fast" : ""}`} alt="logo"/>

  

          
          
            <p>
              {this.state.saying}{this.state.name.firstName}{" "}
              {this.state.name.lastName}
            </p>
  
            <form onSubmit={(event) => this.handleSubmit(event)}>
              <label>


              <Select
        className="custom-react-select"
        value={this.state.template}
        onChange={(selectedOption) => {
          const selectedValue = selectedOption ? selectedOption.value : "";
          this.setState({ template: selectedValue, response: null });
          console.log(selectedValue);
        }}
        options={this.state.options}
        formatOptionLabel={({ label }) => label}
        placeholder={this.state.template ? this.state.template : "Select Template..."}
      />




              </label>
              <br />
              <p></p>
              <label>
                Input findings:

<textarea
        value={this.state.text}
        onChange={this.handleChange}
        className="input-custom-textarea"
      ></textarea>


              </label>
              <br />
              <button type="submit" class="generate-report-button">Generate Report</button>
            
              {/*<button onClick={this.handleAutoPasteClick}>Auto Paste</button> */ } 
              {/* Autopaste function does not seem to work reliably on work browser*/ } 
            </form>

            <h6>Pauley Chea | 2023 | pauleyc@gmail.com</h6>
            </div>
          </header>
        </div>
  
        {showExampleTemplate && <ExampleTemplate template={this.state.template} />}
  
        <div className="api-response-box">
          {this.state.showClipboardMessage && (
            <div className="clipboard-message" style={{ color: "green" }}>
              Report Copied to Clipboard
            </div>
          )}
          <h1 style={{fontSize: "16px"}}> Radiology Report:</h1>
          
          {response !== null && // Check if response is not null
    response
    .replace(/LYMPH_NODES:/g, "LYMPH NODES:")
    .replace(/Pelvic organs:/g, '\r\nPelvic organs:')
    .replace(/Musculoskeletal:/g,'\r\nMusculoskeletal:')
    .replace(/Lower chest: Normal./g, 'Lower chest: Normal.')
    .replace(/Liver: Normal./g, 'Liver: No suspicious liver lesions.')
    .replace(/Biliary: Normal./g, 'Biliary: No biliary dilatation.')
    .replace(/Gallbladder: Normal./g, 'Gallbladder: Normal.')
    .replace(/Pancreas: Normal./g, 'Pancreas: Normal.')
    .replace(/Spleen: Normal./g, 'Spleen: Normal.')
    .replace(/Adrenals: Normal./g, 'Adrenals: Normal.')
    .replace(/Kidneys\/Ureters: Normal./g, 'Kidneys/Ureters: No hydronephrosis or suspicious mass.')
    .replace(/Gastrointestinal tract: Normal./g, 'Gastrointestinal tract: Normal.')
    .replace(/Peritoneal cavity: Normal./g, 'Peritoneal cavity: No ascites. No free air.')
    .replace(/Pelvic organs: Normal./g, 'Pelvic organs: No pelvic masses or inflammatory process is identified.')
    .replace(/Lymph nodes: Normal./g, 'Lymph nodes: No pathologic lymphadenopathy.')
    .replace(/Abdominal wall: Normal./g, 'Abdominal wall: Normal.')
    .replace(/Vascular structures: Normal./g, 'Vascular structures: Patent.')
    .replace(/Musculoskeletal: Normal./g, 'Musculoskeletal: No acute abnormality identified.')
    .replace(/Pulmonary arteries: Normal./g,'')
    //ADD HERE FOR BLUE BOLDED WORDS ON THE FINAL REPORT.
    .replace(/(Lower chest:|Liver:|Biliary:|Gallbladder:|Pancreas:|Spleen:|Adrenals:|Kidneys\/Ureters:|Gastrointestinal tract:|Peritoneal cavity:|Pelvic organs:|Lymph nodes:|Abdominal wall:|Vascular structures:|Musculoskeletal:|LUNGS\/PLEURA:|MEDIASTINUM\/VASCULAR:|LYMPH NODES:|BONES\/SOFT TISSUES:|UPPER ABDOMEN:|LOWER THORAX:|LIVER:|GALLBLADDER\/BILIARY:|PANCREAS:|SPLEEN:|ADRENAL GLANDS:|KIDNEYS\/URETERS:|GI TRACT\/PERITONEUM:|PELVIC ORGANS:|VASCULAR:|Lungs:|Cardiomediastinum:|Osseous:|Lines\/Tubes\/Devices:|Other:|Pulmonary arteries:|Support Devices:|Lungs and Airways:|Airways:|Pleura:|Heart:|Mediastinum and Hila:|Chest Wall and Lower Neck:|Upper Abdomen:|Bones:|Lower thorax:|Gallbladder and biliary system:|Adrenal glands:|Kidneys and ureters:|Stomach and bowel:|Pelvis:|Vasculature:|Soft tissues:|Mediastinum:|Chest Lymph Nodes:|Chest Wall:|Lower Neck:|Chest Bones:|Lower Thorax:|Kidneys:|Ureters:|Upper GI:|Lower GI:|Bowel:|Peritoneal Cavity:|Retroperitoneum:|Bladder:|Prostate:|Ovaries:|Adnexa:|Uterus:|Abd\/Pelvis Lymph Nodes:|Abdominal Wall:|Abd\/Pelvis MSK:|Abd\/Pelvis Bones:|Abd\/Pelvis Soft Tissues:|Abd\/Pelvis Vascular:|RECOMMENDATION:)/g, (match) => `<span class="match">${match}</span>`)
    .split('\n')
    .map((line, index) => (
      <p key={index} dangerouslySetInnerHTML={{__html: line}}></p>
    ))
}

          
              

                
            

       
              
              
               

                <button id="copy-button"onClick={this.handleCopyClick}>Copy</button>
        </div>
        
      </div>
    );
  }

  
  
  

  }
  
  function ExampleTemplate(props) {
  const { template } = props;
  
  let exampleText = "";
  switch (template) {
  case "Lahey:Universal CAP":
  exampleText = "Support Devices: <br><br> Lungs: <br><br> Airways: <br><br> Pleura: <br><br> Heart: <br><br> Mediastinum: <br><br> Chest Lymph Nodes: <br><br> Chest Wall: <br><br> Lower Neck: <br><br> Upper Abdomen: <br><br> Chest Bones: <br><br> Lower Thorax: <br><br> Liver: <br><br> Gallbladder: <br><br> Biliary: <br><br> Pancreas: <br><br> Spleen: <br><br> Adrenals: <br><br> Kidneys: <br><br> Ureters: <br><br> Upper GI: <br><br> Lower GI: <br><br> Bowel: <br><br> Peritoneal Cavity: <br><br> Retroperotineum: <br><br> Pelvis: <br><br> Bladder: <br><br> Prostate: <br><br> Ovaries: <br><br> Adnexa: <br><br> Uterus: <br><br> Abd/Pelvis Lymph Nodes: <br><br> Abdominal Wall: <br><br> Abd/Pelvis MSK: <br><br> Abd/Pelvis Bones: <br><br> Abd/Pelvis Soft Tissues: <br><br> Abd/Pelvis Vascular: <br><br> RECOMMENDATION:";
  break;

  case "Lahey:Chest CT":
  exampleText = "LUNGS/PLEURA: <br><br> MEDIASTINUM/VASCULAR: <br><br> LYMPH NODES: <br><br> BONES/SOFT TISSUES: <br><br> UPPER ABDOMEN: ";
  break;
  case "Lahey:Abdomen/Pelvis":
  exampleText = "LOWER THORAX:<br><br> LIVER: <br><br> GALLBLADDER/BILIARY: <br><br> PANCREAS: <br><br> SPLEEN: <br><br> ADRENAL GLANDS: <br><br> KIDNEYS/URETERS: <br><br> GI TRACT/PERITONEUM: <br><br> LYMPH NODES: <br><br> VASCULAR: <br><br> PELVIC ORGANS: <br><br> BONES/SOFT TISSUES:";
  break;
  case "Lahey:Chest+Abd/Pelvis":
  exampleText = "LUNGS/PLEURA: <br><br> MEDIASTINUM/VASCULAR: <br><br>LYMPH NODES: <br><br> LIVER: <br><br>GALLBLADDER/BILIARY: <br><br>PANCREAS: <br><br>SPLEEN: <br><br>ADRENAL GLANDS: <br><br>KIDNEYS/URETERS: <br><br>GI TRACT/PERITONEUM: <br><br>PELVIC ORGANS: <br><br> VASCULAR: <br><br>LYMPH NODES: <br><br> BONES/SOFT TISSUES: ";
  break;
  case "Lahey:Chest XR":
  exampleText = "Lines/Tubes/Devices: <br><br>Lungs: <br><br>Cardiomediastinum: <br><br>Osseous: <br><br>Other: <br><br>";
  break;
  case "RIMI: Abdomen/Pelvis":
  exampleText = "Lower chest:<br>Liver:<br>Biliary:<br>Gallbladder:<br>Pancreas:<br>Spleen:<br>Adrenals:<br>Kidneys/Ureters:<br>Gastrointestinal tract:<br><br>Perotineal Cavity:<br><br>Pelvic Organs:<br>Lymph Nodes:<br>Abdominal Wall:<br>Vascular Structures:<br><br>Musculoskeletal: ";
 
  }
  
  if (!exampleText) {
  return null;
  }
  
  return (
  <div className="example-template-box">
    
  <h3>Example Template</h3>
  
  <p dangerouslySetInnerHTML={{__html: exampleText}}></p>
  </div>
  );
  }
  
  export default HomePage;

